<template>
  <div>
    <AppHeader is-back-btn-disabled />
    <TTView>
      <VRow class="my-10">
        <VCol
          v-for="(language, i) in preparedLanguages"
          :key="i"
          cols="12"
        >
          <div
            class="lang-title d-flex align-center cursor-pointer pa-4"
            :class="{ 'lang-title__active' : $i18n.locale === language.short }"
            :data-test-label="language.short"
            @click="switchLocale(language.short)"
          >
            <VImg
              :src="require(`@/assets/img/${language.flag}.svg`)"
              max-width="24"
              class="mr-4"
            />

            <div>
              {{ language.title }}
            </div>
          </div>
        </VCol>
      </VRow>

      <VRow>
        <VBtn
          depressed
          large
          color="tt-blue white--text"
          class="custom-action-btn text-none mx-3"
          data-test="next-btn"
          :ripple="$vuetify.breakpoint.lgAndUp"
          @click="updateLanguage"
        >
          {{ $t('goFurther') }}
          <VIcon
            class="tt-black--text mx-2"
            color="tt-white"
            x-small
            dark
          >
            fas fa-chevron-right
          </VIcon>
        </VBtn>
      </VRow>
    </TTView>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import TTView from '@/components/TTView.vue';
import { LOCALES_LIST } from '@/utils/constants';
import { toggleLoading } from '@/plugins/vuex/mutationTypes';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { mapMutations } from 'vuex';

export default {
  name: 'LanguageSwitcher',
  components: { AppHeader, TTView },
  inject: ['Names'],

  props: {
    userSurveySlug: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    firstPageIsOrgstructure: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      LOCALES_LIST,
    };
  },

  computed: {
    preparedLanguages() {
      return this.languages.map((language) => this.LOCALES_LIST.find((locale) => locale.short === language.language));
    },
    isTestingFulfillment() {
      return this.$route.name === this.Names.R_SURVEY_TEST;
    },
  },
  watch: {
    languages: {
      handler(newLanguages) {
        const defaultLanguage = newLanguages.find((lang) => lang.default);
        this.$i18n.locale = defaultLanguage.language;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations([toggleLoading]),
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) this.$i18n.locale = locale;
    },
    async updateLanguage() {
      this[toggleLoading](true);
      try {
        await apiService.surveyLanguageUpdate(this.userSurveySlug, {
          current_language: this.$i18n.locale,
        });
        const query = this.isTestingFulfillment ? { testing: this.isTestingFulfillment } : {};

        if (this.firstPageIsOrgstructure) {
          this.$router.push({
            name: this.Names.R_SURVEY_ORGSTRUCTURE,
            params: { slug: this.userSurveySlug },
            query,
          });
          return;
        }

        this.$router.push({
          name: this.Names.R_USER_SURVEY_PAGE,
          params: { slug: this.userSurveySlug },
          query,
        });
        return;
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-action-btn.v-btn {
  font-size: 14px !important;
  font-weight: 500;
}

.lang-title {
  width: fit-content;
  &__active {
    background-color: #d9f5ff;
    border-radius: 4px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
